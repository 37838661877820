.footer-wrapper{
    display: flex;
    align-items: center;
    margin-top: 50px;
    .logo-wrapper{
        width: 40%;
        margin-left: 15px;

        img{
            width: 100px;
        }
    }
    .black-bar{
        width: 60%;
        border-bottom-left-radius: 100px;
        height: 30px;
        background-color: $color-second;
    }
}