.desktop-content{
    

    .logo-section{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 5%;
        height: 100vh;
        background-color: white;

        .logo-wrapper{
            margin-top: 25%;
        }
        .logo-info{
            font-size: 40px;
        }
    }
    
    .section-header{
        font-weight: bold;
        font-size: 40px;
    }

    .section-info{
        max-width: 100vh;
        font-size: 35px;
    }

    .aboutUs-section{
        display: flex;
        justify-content: space-between;
        margin: 0 5%;
        margin-top: 30px;

        .sand-photo {
            width: auto;
            max-width: 400px;
            max-height: 500px;
        }
    }

    .applications-section{
        .section-header{
            margin-left: 50px;
        }
    }
    .product-application{
        max-width: 400px;
        margin: 0 30px;

        .product-info{
            font-size: 24px;
        }
    }

    .contact-and-collaborators{
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .sub-section{
            margin: 0 50px;
            width: 50%;
        }

        .collaborators-image{
            width: 250px;
        }
    }
}