@import './variables.scss';

@font-face {
    font-family: GlacialIndifference;
    src: url('../fonts/GlacialIndifference-Regular.otf');
}

@font-face {
    font-family: GlacialIndifference;
    font-weight: bold;
    src: url('../fonts/GlacialIndifference-Bold.otf');
}

@font-face {
    font-family: GlacialIndifference;
    font-style: italic;
    src: url('../fonts/GlacialIndifference-Italic.otf');
}

body{
    width: 100%;
    height: 100%;
    margin: 0;
    color: $color-second;
    font-family: GlacialIndifference;
}
.main-page{
    width: 100%;
}


@import './navBar/smallNavBar.scss';
@import './navBar/largeNavBar.scss';

@import './content/mobileContent.scss';
@import './content/desktopContent.scss';

@import './contactForm.scss';
@import './footer.scss';