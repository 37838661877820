.mobile-content{
    margin-top: 100px;

    .section{
        margin: 15px;

        .section-header{
            font-weight: bold;
            font-size: 24px;
        }
    }

    .contact-form-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.product-application-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .products-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .product-application{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5px;
        max-width: 200px;

        .image-wrapper{
            background-color: #FADAC7;
            padding: 15px;
            border-radius: 25%;

            img{
                width: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }
        .product-info{
            font-weight: bold;
        }
    }
}
.collaborators-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .collaborators-image{
        width: 150px;
        display: flex;
        align-items: center;

        img{
            width: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }
}