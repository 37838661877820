.small-nav-bar{
    padding-top: 15px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    
    
    .burger-menu{
        position: absolute;
        top: 20px;
        left: 15px;
    }

    .logo-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;

        img{
            width: 200px;
        }
    }
    .menu-content{
        position: absolute;
        background-color: $color-main;
        width: 100%;
        padding: 15px;
    }
}