.contact-form-wrapper{
    
    .input-wrapper{
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        width: 60%;

        input{
            margin-top: 5px;
            border-radius: 5px;
            border-width: 1px;
            border-color: #707070;
            border-style: solid;
            padding: 3px;
        }

        input:focus-visible{
            outline: $color-main auto 1px;
        }

        .phoneInput-wrapper{
            width: 100%;
        }
    }

    .button-wrapper{
        margin: 15px;
        width: 40%;

        .save-button{
            width: 100%;
            padding: 5px 15px;
            border-radius: 5px;
            border-color: $color-main;
            border-style: solid;
            border-image: none;
            background-color: $color-main;
        }

        .save-button:active,
        .save-button:focus-visible,
        .save-button:focus-within,
        .save-button:hover{
            background-color: #F8A371;
        }
    }
}